import React from "react";

import {Container, Row, Col, UncontrolledTooltip} from "reactstrap";
import {
    EnterpriseSponsors,
    BusinessSponsors,
    BasicSponsors,
    StarterSponsors,
    Organizers,
    Partners
} from '../../../const';
import {FormattedMessage} from 'react-intl';

export const HorizontalSponsors: React.FC = () => (
    <>
        <section className="section">
            <Container>
                <Row className="row-grid justify-content-center">
                    <Col className="text-center" lg="8">
                        <div className="text-center">
                            <h4 className="display-4 mb-5 mt-5">
                                <FormattedMessage id="sponsors"/>
                            </h4>
                            <Row className="justify-content-center">
                                {EnterpriseSponsors.map(sponsor => (
                                    <Col lg="3" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {BusinessSponsors.map(sponsor => (
                                    <Col lg="3" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {BasicSponsors.map(sponsor => (
                                    <Col lg="3" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {StarterSponsors.map(sponsor => (
                                    <Col lg="3" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <h4 className="display-5 mb-5 mt-5">
                                <FormattedMessage id="sponsors.organizers"/>
                            </h4>
                            <Row className="justify-content-center">
                                {Organizers.map(sponsor => (
                                    <Col lg="3" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/organizers/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <h4 className="display-5 mb-5 mt-5">
                                <FormattedMessage id="sponsors.partners"/>
                            </h4>
                            <Row className="justify-content-center">
                                {Partners.map(sponsor => (
                                    <Col lg="2" xs="3" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/partners/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
);

export const VerticalSponsors: React.FC = () => (
    <>
        <section className="section section-sm">
            <Container>
                <Row className="row-grid justify-content-center">
                    <Col className="text-center" lg="8">
                        <div className="text-center">
                            <h4 className="display-4 mb-5 mt-5">
                                <FormattedMessage id="sponsors"/>
                            </h4>
                            <Row className="justify-content-center">
                                {EnterpriseSponsors.map(sponsor => (
                                    <Col lg="4" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {BusinessSponsors.map(sponsor => (
                                    <Col lg="4" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {BasicSponsors.map(sponsor => (
                                    <Col lg="4" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="justify-content-center">
                                {StarterSponsors.map(sponsor => (
                                    <Col lg="4" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/all/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <h4 className="display-5 mb-5 mt-5">
                                <FormattedMessage id="sponsors.organizers"/>
                            </h4>
                            <Row className="justify-content-center">
                                {Organizers.map(sponsor => (
                                    <Col lg="4" xs="4" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/organizers/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                            <h4 className="display-5 mb-5 mt-5">
                                <FormattedMessage id="sponsors.partners"/>
                            </h4>
                            <Row className="justify-content-center">
                                {Partners.map(sponsor => (
                                    <Col lg="4" xs="3" key={sponsor['id']}>
                                        <a
                                            href={sponsor["link"]}
                                            id={"sp_" + sponsor['id']}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../../assets/img/race/sponsors/partners/" + sponsor['id'] + ".png")}
                                            />
                                        </a>
                                        <UncontrolledTooltip delay={0} target={"sp_" + sponsor['id']}>
                                            {sponsor["name"]}
                                        </UncontrolledTooltip>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
);
