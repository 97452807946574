import {useIntl} from 'react-intl';
import {DefaultLanguage} from "../../../const";

export const Route = (path: string) => {
    const {formatMessage, locale} = useIntl();
    if (locale === DefaultLanguage)
        return formatMessage({id: path});
    else
        return `/${locale}` + formatMessage({id: path});
};

export default Route;