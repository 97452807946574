import React from "react";
import {Helmet} from "react-helmet";
import {useIntl} from "react-intl";

import {Col, Container, Row,} from "reactstrap";
import {VerticalSponsors} from '../../modules/layout';

export const Regulation: React.FC = () => {
  const {formatMessage} = useIntl();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>{formatMessage({id: "info.regulation.title"})} | SantYaGo10K 2025</title>
      </Helmet>
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-sm section-hero section-shaped">
            <div className="shape shape-style-1 shape-dark">
              <span/> <span/> <span/> <span/> <span/>
              <span/> <span/> <span/> <span/>
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <h1 className="display-3 text-white">
                      {formatMessage({id: "info.regulation.title"})}
                    </h1>
                    <p className="lead text-white">
                      {formatMessage({id: "info.regulation.subtitle"})}
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
          <section className="section section-sm">
            <Container>
              <Row>
                <Col lg="8">
                  <Row>
                    <Col>
                      <p>
                        {formatMessage({id: "info.regulation.link"})}
                      </p>

                      <p>
                        <strong>
                          <a href="https://info.santyago10k.run/2025/reglamento/" target="_blank" rel="noopener noreferrer">
                            info.santyago10k.run/2025/reglamento
                          </a>
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg="4">
                  <VerticalSponsors/>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </main>
    </>
  );
};

export default Regulation;
