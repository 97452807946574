import React from 'react';
import {Link} from 'react-router-dom';
import {AppRoute, AppRouteTitles} from '../../../const';
import {useIntl} from 'react-intl';

import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

import {CONTACT, INSTAGRAM, FACEBOOK, TWITTER} from "../../../const";
import {Route} from "../../i18n";


export const Footer: React.FC = () => {
    const {formatMessage} = useIntl();

    return (
        <>
            <footer className="footer has-cards">
                <Container>
                    <Row className="row-grid align-items-center my-md">
                        <Col lg="6">
                            <h3 className="text-primary font-weight-light mb-2 text-orange">
                                {formatMessage({id: "footer.heading"})}
                            </h3>
                            <h4 className="mb-0 font-weight-light">
                                {formatMessage({id: "footer.subheading"})}
                            </h4>
                        </Col>
                        <Col className="text-lg-center btn-wrapper" lg="6">
                            <Button
                                className="btn-neutral btn-icon-only btn-round"
                                color="dribble"
                                href={"mailto:" + CONTACT}
                                id="tp_mail"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-envelope-o"/>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tp_mail">
                                {CONTACT}
                            </UncontrolledTooltip>
                            <Button
                                className="btn-neutral btn-icon-only btn-round ml-1"
                                color="twitter"
                                href={"https://www." + TWITTER}
                                id="tp_tw"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-twitter"/>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tp_tw">
                                {TWITTER}
                            </UncontrolledTooltip>
                            <Button
                                className="btn-neutral btn-icon-only btn-round ml-1"
                                color="facebook"
                                href={"https://www." + FACEBOOK}
                                id="tp_fb"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-facebook-square"/>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tp_fb">
                                {FACEBOOK}
                            </UncontrolledTooltip>
                            <Button
                                className="btn-neutral btn-icon-only btn-round ml-1"
                                color="instagram"
                                href={"https://www." + INSTAGRAM}
                                id="tp_ig"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-instagram"/>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tp_ig">
                                {INSTAGRAM}
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="align-items-center justify-content-md-between">
                        <Col md="6">
                            <div className="copyright">
                                <a
                                    href="https://diego.gal/?ref=10k"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-orange"
                                >
                                    Diego Barreiro Perez
                                </a>
                                &nbsp;©&nbsp;{new Date().getFullYear()}{" "}
                            </div>
                        </Col>
                        <Col md="6">
                            <Nav className="nav-footer justify-content-end">
                                <NavItem>
                                    <NavLink to="/" tag={Link}>
                                        SantYaGo10K
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://info.santyago10k.run/" onClick={e => {
                                        e.preventDefault();
                                        window.open("https://info.santyago10k.run/", "_blank");
                                    }}>
                                        Info
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={Route(AppRoute.Contact)} tag={Link}>
                                        {formatMessage({id: AppRouteTitles.get(AppRoute.Contact) || ''})}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://info.santyago10k.run/legal/" onClick={e => {
                                        e.preventDefault();
                                        window.open("https://info.santyago10k.run/legal/", "_blank");
                                    }}>
                                        {formatMessage({id: "footer.legal"})}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
