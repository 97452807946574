import React from 'react';
import {Navigation, Footer} from '../../navigation';

export const AppLayout: React.FC = ({children}) => (
    <div>
        <Navigation/>
        {children}
        <Footer/>
    </div>
);
