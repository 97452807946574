import React from "react";

import {
    Container,
    Row,
    Col,
    Badge,
    Card,
    CardBody,
    Button
} from "reactstrap";
import {useIntl, FormattedDate} from "react-intl";
import {Helmet} from "react-helmet";
import {VerticalSponsors} from '../../modules/layout';
import {Press as PressItems} from "../../const";

export const Press: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "info.press.title"})} | SantYaGo10K 2025</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-sm section-hero section-shaped">
                        <div className="shape shape-style-1 shape-dark">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "info.press.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "info.press.subtitle"})}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    {PressItems.map(press => (
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody className="py-5">
                                                <Row>
                                                    <Col lg="3" xs="12">
                                                        <Badge color="warning" pill className="mr-1">
                                                            <FormattedDate
                                                                value={new Date(press['date'] * 1000)}
                                                                year="numeric"
                                                                month="2-digit"
                                                                day="2-digit"
                                                            />
                                                        </Badge>
                                                        <img
                                                            alt="..."
                                                            className="img-fluid"
                                                            src={require("../../assets/img/race/press/" + press['provider'] + ".png")}
                                                        />
                                                    </Col>
                                                    <Col lg="9" xs="12">
                                                        <p className="text-warning text-uppercase">
                                                            {press['title']}
                                                        </p>
                                                        <Button
                                                            className="mt-4"
                                                            color="warning"
                                                            href={press['source']}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {formatMessage({id: "info.press.read"})}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Press;
