import {ADMIN} from "../../const";
import md5 from "md5";
import Cookies from "universal-cookie";

export const Protect = () => {
    const cookies = new Cookies();
    let counter = 0;
    let upass = cookies.get("apass") || prompt('Introduzca o contrasinal', '');
    let grant = false;
    while (counter < 3) {
        if (upass === null)
            upass = "";
        if (md5(upass) === ADMIN) {
            grant = true;
            break;
        } else {
            cookies.remove("apass");
        }
        counter += 1;
        console.log(counter);
        upass = prompt('Contrasinal incorrecto. Inténteo de novo.', '');
    }

    if (!grant) {
        window.location.replace("");
    } else {
        cookies.set('apass', upass, { path: '/' });
    }
    return;
};

export default Protect;