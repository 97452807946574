export const FACEBOOK = "facebook.com/SantYaGo10K";
export const TWITTER = "twitter.com/SantYaGo10K";
export const INSTAGRAM = "instagram.com/santyago10k";

export const CONTACT = "info@santyago10k.run";

export const ADMIN = "7ef10ead08fe599093d8148c336055bb";


export const MYMAPS_ID = "1oC6ky1LRVACQymnmdAdU8FzIkedrpYI";
