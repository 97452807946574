import React from "react";

import {
    Container,
    Row,
    Col,
    Badge,
} from "reactstrap";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import {VerticalSponsors} from '../../modules/layout';

export const Terms: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "info.terms.title"})} | SantYaGo10K 2025</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-sm section-hero section-shaped">
                        <div className="shape shape-style-1 shape-dark">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "info.terms.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "info.terms.subtitle"})}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col>
                                            <Badge color="info" pill className="mr-1">
                                                {formatMessage({id: "info.regulation.galician"})}
                                            </Badge>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <p>Tódolos corredores, ó realizar a inscrición, aceptan a publicación do
                                                seu nome e imaxe nas clasificacións, medios de comunicación, internet,
                                                ou cualquiera outro medio que a organización poida requerir.</p>

                                            <p>Mediante a inscrición, o participante consinte expresamente que os seus
                                                datos de contacto poidan ser cedidos e empregados polos organizadores
                                                do evento, os organizadores, as empresas que patrocinadoras, e a
                                                Federación Galega de Atletismo.</p>

                                            <p>Asimesmo consinte expresamente que as imaxes ou vídeos que se tomen
                                                durante o evento e nas que apareza poidan ser publicadas nas webs e/ou
                                                redes sociais dos organizadores do evento, os organizadores, as empresas
                                                que patrocinadoras, ou a Federación Galega de Atletismo.</p>

                                            <p>Esas imaxes ou vídeos poderán ser obxeto de uso en mostras, exposicións
                                                ou catálogos, ou para promocionar futuros eventos deportivos, concursos
                                                ou actividades. Os organizadores resérvanse os dereitos de utilización,
                                                distribución, comunicación pública, transformación, exhibición ou
                                                reproducción, así como cualquera outro dereito de explotación por
                                                periodo indefinido.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Terms;
