import React from "react";

import {
    Container,
    Row,
    Col,
    Alert
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import {VerticalSponsors} from '../modules/layout';

export const Volunteer: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "volunteer.title"})} | SantYaGo10K 2025</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-sm section-hero section-shaped">
                        <div className="shape shape-style-1 shape-dark">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "volunteer.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "volunteer.subtitle"})}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h3>{formatMessage({id: "volunteer.heading"})}</h3>
                                    <p>{formatMessage({id: "volunteer.p1"})}</p>
                                    <p>{formatMessage({id: "volunteer.p2"})}</p>
                                    <Alert color="warning">
                                        <span className="alert-inner--text">
                                            <FormattedMessage
                                                id="volunteer.a1"
                                                tagName="strong"
                                                values={{
                                                    sup: (chunks: React.ReactNode) => (
                                                        <sup>{chunks}</sup>
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </Alert>
                                    <p>{formatMessage({id: "volunteer.p3"})}</p>
                                    <iframe
                                        title="volunteer"
                                        src="https://docs.google.com/forms/d/e/1FAIpQLScQIyp2IaoiFGJbMzYb5kAFNnufkarw6y6VPjTd374z0SA0sw/viewform?embedded=true"
                                        width="100%"
                                        height="500"
                                        frameBorder="0"
                                    >
                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScQIyp2IaoiFGJbMzYb5kAFNnufkarw6y6VPjTd374z0SA0sw/viewform">
                                            Link
                                        </a>
                                    </iframe>
                                    <br/>
                                    <br/>
                                    <Alert color="info">
                                        <span className="alert-inner--icon">
                                            <i className="fa fa-child"/>
                                        </span>
                                        <FormattedMessage
                                            id="volunteer.a2"
                                            values={{
                                                a: (chunks: React.ReactNode) => (
                                                    <a
                                                        className="text-white"
                                                        href="https://drive.google.com/file/d/1U-0RIOsCcEiXv9ida908-rnvhxh3Pgv_/view?usp=sharing"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <u>{chunks}</u>
                                                    </a>
                                                ),
                                                b: (chunks: React.ReactNode) => (
                                                    <strong>{chunks}</strong>
                                                ),
                                            }}
                                        />
                                    </Alert>
                                    <Alert color="default">
                                        <span className="alert-inner--icon">
                                            <i className="fa fa-graduation-cap"/>
                                        </span>
                                        <FormattedMessage
                                            id="volunteer.a3"
                                            values={{
                                                b: (chunks: React.ReactNode) => (
                                                    <strong>{chunks}</strong>
                                                ),
                                                a: (chunks: React.ReactNode) => (
                                                    <a
                                                        className="text-white"
                                                        href="https://www.usc.es/gl/servizos/sepiu/sc101/voluntariado.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <u>{chunks}</u>
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </Alert>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Volunteer;
