import React from "react";
import {Helmet} from 'react-helmet';

import { Badge, Col, Container, Row, } from "reactstrap";
import {useIntl} from "react-intl";
import {EatingDinner, VerticalSponsors} from "../../modules/layout";


export const Eating: React.FC = () => {
  const {formatMessage} = useIntl();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>{formatMessage({id: "before.eating.title"})} | SantYaGo10K 2025</title>
      </Helmet>
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped">
            <div className="shape shape-style-1 bg-gradient-blue">
              <span/> <span/> <span/> <span/> <span/>
              <span/> <span/> <span/> <span/>
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    {/* TODO: Confirm */}
                    <Badge color="warning" pill className="mr-1">
                      {formatMessage({id: "wip.confirmation"})}
                    </Badge>
                    <h1 className="display-3 text-white">
                      {formatMessage({id: "before.eating.title"})}
                    </h1>
                    <p className="lead text-white">
                      {formatMessage({id: "before.eating.subtitle"})}
                    </p>
                  </Col>
                  <Col lg="6">
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-lg"
                      src={require('../../assets/img/race/pages/eating.jpg')}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
          <section className="section section-sm">
            <Container>
              <Row>
                <Col lg="8">
                  <EatingDinner/>
                </Col>
                <Col lg="4">
                  <VerticalSponsors/>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </main>
    </>
  );
};

export default Eating;
