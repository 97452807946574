import React from "react";

import {Row, Col, Card} from "reactstrap";
import {FormattedMessage} from 'react-intl';

export const Moving: React.FC = () => (
    <>
        <Row className="mt-5">
            <Col>
                <Card className="bg-gradient-default shadow-lg border-0">
                    <div className="p-5">
                        <h3 className="text-white">
                            <FormattedMessage id="generic.moving.title"/>
                        </h3>
                        <p className="lead text-white mt-3">
                            <FormattedMessage id="generic.moving.subtitle"/>
                        </p>
                    </div>
                </Card>
            </Col>
        </Row>
    </>
);
